var myScroll;

// Função carregada no onload do browser
function loaded() {

  if ( $( window ).width() < 1200 ) {
    /*
    * Iniciala o plugin do IScroll
    */
    myScroll = new IScroll(
      '#wrapper'
      , {
        probeType: 3
        , tap: true
        , click: true
        , mouseWheel: true
        , scrollbars: true
        , click: false
        , preventDefaultException: {
          tagName: /.*/
        }
      });

    // Devido os cards estarem com position absolute
    // a cada 1s o iscroll sofre um refresh para garantir que a altura
    // do iScroll seja a correta
    setInterval( function() {
      myScroll.refresh();
    }, 1000);

    myScroll.on( 'scroll', function() {

      if( Math.abs( myScroll.y ) >= 40 ) {
        $( '.menu-icon-wrapper' ).addClass( 'in-scroll' );
      }
      else {
        $( '.menu-icon-wrapper' ).removeClass( 'in-scroll' );
      }
    });
  }
}
