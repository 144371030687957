( function(  $ ) {

  $( document ).on( 'click', '.menu-icon-wrapper', function( event ) {
    event.preventDefault();

    $( '.navigation-mobile' ).toggleClass( 'is-submenu-open' );
  });

  $( document ).on( 'click', '.close-menu-mobile', function( event ) {
    $( '.navigation-mobile' ).toggleClass( 'is-submenu-open' );
  })

  $( document ).on( 'click', '.item-submenu-footer', function( event ) {
    event.preventDefault();

    $( '.item-submenu-footer' ).removeClass( 'submenu-item-active' );

    $( this ).addClass( 'submenu-item-active' );

    $( '.navigation-mobile' ).toggleClass( 'is-submenu-open' );
  });

})( jQuery );
