( function( $ ) {

  $( document ).on( 'click', '.link-sections', function( event ) {
    event.preventDefault();

    var thisElement = $( this ),
        elementTarget = $( $( this ).attr( 'href' ) ),
        prevElement = $( '.in-view' );

    if ( Math.abs( myScroll.y ) > 0 )
      // Faz o container do iScroll voltar para o marco 0 antes da troca dos blocos de conteúdo
      myScroll.scrollTo(0, 0, 1000, IScroll.utils.ease.circular);

    else
      checkItemActive();

    function checkItemActive() {
      if ( elementTarget.hasClass( 'in-view' ) ) {
        return false;
      }

      else if ( elementTarget.hasClass( 'in-hidden' ) ) {
        elementTarget.velocity( 'reverse' ).removeClass( 'in-hidden' ).addClass( 'in-view' );
        prevElement.velocity( 'reverse' ).removeClass( 'in-view' ).addClass( 'in-hidden' );
      }

      else {
        prevElement.velocity({
          right: "200%"
        }).removeClass( 'in-view' ).addClass( 'in-hidden' );

        $( elementTarget ).velocity({
          right: 0
        }).addClass( 'in-view' );
      }
    }

    // Dá um delay de 1s para garantir que a animação dos blocos só ocorra depois que o iScroll voltar para o marco 0
    setTimeout( function() {

      // As classes in-view e in-hidden server para indicar qual a direção da animação

      $( '.item-navigation-footer' ).removeClass( 'item-active' );

      $( thisElement ).parent().addClass( 'item-active' );
      checkItemActive();

    }, 1000);
  });

})( jQuery );
