( function( $ ) {
  if ( $( '.testimony-slider' ).length ) {
    var testimonySlider = new Swiper('.testimony-slider', {
      speed: 400,
      spaceBetween: 20,
      slidesPerView: 3,
      pagination: $( '.pagination-testimony' ),
      paginationClickable: true
    });
  }
})( jQuery );