( function( $ ) {
  $( document ).on( 'click', '.scf-send-form', function( event ) {
    event.preventDefault();

    var formData = $( this ).parents( '.send-contact-form' ),
        response;

    if ( formData.valid() && !$( this ).hasClass( 'button-loading' ) ) {
      $.ajax({
        url: '/send-mail/send-email.php',
        type: 'POST',
        data: formData.serialize(),
        beforeSend: function() {
          $( '.scf-send-form' ).addClass( 'button-loading' ).attr( 'disabled', true );
        }
      })
      .done(function(data) {
        response = $.parseJSON(data);

        if ( response ) {
          swal( "", "Seu email foi enviado com sucesso. Em breve entraremos em contato", "success" );
        }
        else {
          swal( "", "Seu email foi enviado com sucesso. Em breve entraremos em contato", "error" );
        }

        $( '.scf-send-form' ).removeClass( 'button-loading' ).attr( 'disabled', false );
        formData[0].reset();
      })
      .fail(function(data) {
        response = $.parseJSON(data);

        if ( response ) {
          swal( "", "Seu email foi enviado com sucesso. Em breve entraremos em contato", "success" );
        }
        else {
          swal( "", "Seu email foi enviado com sucesso. Em breve entraremos em contato", "error" );
        }

        $( '.scf-send-form' ).removeClass( 'button-loading' ).attr( 'disabled', false );
      });
    }
    else {
      if ( $( '.error' ).parent().find( 'span' ).length == 0 )
        $( '.error' ).parent().append( $( "<span></span>" ).addClass( 'invalid' ) );

      return false;
    }
  });
})( jQuery );