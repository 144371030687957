( function( $ ) {

  $( document ).on( 'focus', 'input, textarea', function() {
    $('.navigation-footer').addClass('hidden-footer-mobile');
  });

  $( document ).on( 'blur', 'input, textarea', function() {
    $('.navigation-footer').removeClass('hidden-footer-mobile');
  });

})( jQuery );