( function( $ ) {

  setTimeout( function() {
    $( '.container-loader' ).fadeOut( 'slow' );
  }, 3000);

  setTimeout( function() {
    $( '.loading-facebook' ).fadeOut( 'slow' );
  }, 11000);

})( jQuery );